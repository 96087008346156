var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('start-page',{on:{"getEnd":_vm.getEnd}}),_c('div',{staticClass:"box1"},[_c('div',{staticClass:"box1-inner flex flex-wrap",style:({ top: _vm.aniStar ? '0' : '450px' })},_vm._l((_vm.index_news),function(item,index){return _c('div',{key:index,staticClass:"son_div",attrs:{"to":{ path: '/newsview', query: { id: item.id } }}},[_c('div',{staticClass:"image hover-deepen",on:{"click":function($event){return _vm.tolinka(item.url)}}},[_c('img',{attrs:{"src":item.photo && _vm.$imgurl + item.photo.url,"alt":""}})]),_c('div',{staticClass:"txt ellipsis3",domProps:{"innerHTML":_vm._s(item.title)},on:{"click":function($event){return _vm.tolinka(item.url)}}}),_c('div',{staticClass:"link",on:{"click":function($event){return _vm.tolinka(item.url)}}},[(index == 0)?_c('img',{attrs:{"src":_vm.icon_1[0],"alt":""}}):_vm._e(),(index == 1)?_c('img',{attrs:{"src":_vm.icon_1[1],"alt":""}}):_vm._e(),(index == 2)?_c('img',{attrs:{"src":_vm.icon_1[2],"alt":""}}):_vm._e(),_c('span',[_vm._v("MORE +")])])])}),0)]),_vm._m(0),_c('div',{staticClass:"box3"},[_c('my-amit',{attrs:{"ind":_vm.selected}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.index_trade[0]),expression:"index_trade[0]"}],staticClass:"text"},[_c('div',{staticClass:"txt",on:{"click":function($event){return _vm.toPath(_vm.index_trade[_vm.selected].id)}}},[_vm._v(" "+_vm._s(_vm.index_trade[_vm.selected].title)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.index_trade[_vm.selected].content)},on:{"click":function($event){return _vm.toPath(_vm.index_trade[_vm.selected].id)}}}),_c('div',{staticClass:"imglogo"},[_c('img',{attrs:{"src":_vm.index_trade[_vm.selected].exp1 &&
            _vm.$imgurl + _vm.index_trade[_vm.selected].exp1.url,"alt":""}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.index_trade[0]),expression:"index_trade[0]"}],staticClass:"tabs"},_vm._l((_vm.index_trade),function(item,index){return _c('div',{key:index,staticClass:"tablist cursor",class:[{ hover: _vm.selected == index }],on:{"click":function($event){return _vm.getsele(index)},"mousemove":function($event){_vm.secuind = index},"mouseout":function($event){_vm.secuind = -1}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected == index|| _vm.secuind == index),expression:"selected == index|| secuind == index"}],attrs:{"src":item.exp3 && _vm.$imgurl + item.exp3.url,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.selected == index|| _vm.secuind == index)),expression:"!(selected == index|| secuind == index)"}],attrs:{"src":item.exp2 && _vm.$imgurl + item.exp2.url,"alt":""}}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(item.title))])])}),0)],1),_c('div',{staticClass:"box4"},[_c('div',{staticClass:"title",staticStyle:{"color":"#333"}},[_vm._v(" Industry Solutions ")]),(_vm.index_solution.length > 0)?_c('div',{staticClass:"cont"},[_c('div',{staticClass:"left"},_vm._l((_vm.index_solution.filter(
            (items, indexs) => indexs % 2 == 0
          )),function(item,index){return _c('div',{key:item.id,staticClass:"left_list cursor",class:_vm.selected2 == index * 2 ? 'activeL' : '',on:{"click":function($event){return _vm.changeBox4Li(index, 'l', item.id)},"mouseover":function($event){return _vm.changelist(index, 'l', item.id)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.cindex == item.id),expression:"cindex == item.id"}],attrs:{"src":item.exp2 && _vm.$imgurl + item.exp2.url,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.cindex !== item.id),expression:"cindex !== item.id"}],attrs:{"src":item.photo && _vm.$imgurl + item.photo.url,"alt":""}}),_c('div',{staticClass:"bar"},[_vm._v(_vm._s(item.title))])])}),0),_c('div',{staticClass:"middle",style:({
          background: `url(${
            _vm.$imgurl + _vm.index_solution[_vm.selected2].exp1.url
          }) 0 0/cover no-repeat`,
        })},[_c('div',{staticClass:"txt",on:{"click":function($event){return _vm.tolinktxt(_vm.index_solution[_vm.selected2].id)}}},[_vm._v(" "+_vm._s(_vm.index_solution[_vm.selected2].title)+" ")]),_vm._l((_vm.index_solution[_vm.selected2].second),function(item,indxe){return _c('div',{key:indxe,staticClass:"txt_list",on:{"click":function($event){return _vm.tolink(_vm.index_solution[_vm.selected2].id, item.id)}}},[_c('div',{domProps:{"innerHTML":_vm._s(item.title)}})])})],2),_c('div',{staticClass:"right"},_vm._l((_vm.index_solution.filter(
            (items, indexs) => indexs % 2 == 1
          )),function(item,index){return _c('div',{key:item.id,staticClass:"right_list cursor",class:_vm.selected2 == index * 2 + 1 ? 'activeR' : '',on:{"click":function($event){return _vm.changeBox4Li(index, 'r', item.id)},"mouseover":function($event){return _vm.changelist(index, 'r', item.id)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.cindex == item.id),expression:"cindex == item.id"}],attrs:{"src":item.exp2 && _vm.$imgurl + item.exp2.url,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.cindex !== item.id),expression:"cindex !== item.id"}],attrs:{"src":item.photo && _vm.$imgurl + item.photo.url,"alt":""}}),_c('div',{staticClass:"bar"},[_vm._v(_vm._s(item.title))])])}),0)]):_vm._e()]),_c('div',{staticClass:"box5"},[_c('div',[_c('div',{staticClass:"title"},[_vm._v("TO BECOME THE WORLD'S LEADING FINTECH COMPANY")]),_c('router-link',{staticClass:"txt_a",attrs:{"tag":"div","to":"/about"}},[_vm._v(" MORE")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box2"},[_c('div',{staticClass:"title mar"},[_vm._v(" Business Field ")])])
}]

export { render, staticRenderFns }